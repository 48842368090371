import React from "react";
import { Skeleton } from "@purpur/purpur";
import styled from "styled-components";

const Title = styled(Skeleton)`
  width: 100%;
  height: 15rem;
  margin: 4rem;
`;

const Section = styled(Skeleton)`
  width: 100%;
  height: 50rem;
  margin: 4rem;
`;
const Wrapper = styled.section`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
`;
const TermsSkeleton = () => {
  return (
    <Wrapper>
      <Title />
      <Section />
      <Section />
      <Section />
      <Section />
      <Section />
      <Section />
      <Section />
    </Wrapper>
  );
};

export default TermsSkeleton;
