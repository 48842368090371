import * as React from "react";
import styled from "styled-components";
import { Grid } from "@purpur/purpur";
import { Heading } from "@purpur/purpur";
import { TextSpacing } from "@purpur/purpur";
import { useMessage } from "@messageformat/react";
import { OrderedTermList } from "./OrderedTermList";

const Wrapper = styled(Grid)`
  margin: var(--purpur-spacing-800) auto;
  width: 50%;
`;

export type MessageObject = { [key: string]: () => string };
export type NestedMessageObject = { [key: string]: () => string };

function App() {
  const title = useMessage("heading");

  return (
    <section data-testid="b2b-terms-root" id="main">
      <Wrapper>
        <TextSpacing>
          <Heading tag="h1" variant="title-700">
            {title}
          </Heading>
          <OrderedTermList />
        </TextSpacing>
      </Wrapper>
    </section>
  );
}

export default App;
