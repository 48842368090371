import React from "react";

import { MessageObject } from "../App";
import { Paragraph } from "@purpur/purpur";
import { containsLink, parseLink } from "../../utils/linkParsing";

export function Content({ paragraphs }: { paragraphs: MessageObject }) {
  return (
    <>
      {Object.values(paragraphs).map((entry, index) => {
        let result = undefined;
        const paragraph = entry();
        if (containsLink(paragraph)) {
          result = parseLink(paragraph);
        } else {
          result = paragraph;
        }

        return <Paragraph key={`p-${index}}`}>{result}</Paragraph>;
      })}
    </>
  );
}
