import { PropsWithChildren } from "react";
import React from "react";
import { MessageProvider } from "@messageformat/react";

import { useMessageLocale } from "../../hooks/useMessageLocale";
import TermsSkeleton from "../TermsSkeleton";

export const Scaffolding = ({ children }: PropsWithChildren<unknown>) => {
  const messages = useMessageLocale();

  if (!messages) {
    return <TermsSkeleton />;
  }
  return <MessageProvider messages={messages}>{children}</MessageProvider>;
};
