import React, { useCallback, useState } from "react";

import styled from "styled-components";
import { IconCheckmark } from "@purpur/purpur";

export interface TableProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  tableDataPath: string;
}

import { Heading } from "@purpur/purpur";
import { TableData } from "../../../@types/table";
import { TableBody, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

const Cell = styled.span`
  font-size: var(--purpur-typography-scale-100);
`;
const StyledTableRow = styled(TableRow)`
  border: 1px solid var(--purpur-color-gray-200);
`;
const StyledTableCell = styled(TableCell)`
  border-right: 1px solid var(--purpur-color-gray-200);
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 24px;
  height: 24px;
`;

const TermsTable = ({ tableDataPath, ...props }: TableProps) => {
  const [tableData, setTableData] = useState<TableData>();
  const getTableData = useCallback(async () => {
    const data = (await import(`./${tableDataPath}`)).default;
    setTableData(data);
  }, []);

  getTableData();

  if (!tableData) {
    return null;
  }

  function renderIconOrText(value: string) {
    if (value === "x") {
      return (
        <IconContainer aria-label="checkmark" role="img">
          <IconCheckmark size="xxs" />
        </IconContainer>
      );
    } else if (value === "-") {
      return <></>;
    } else return value;
  }

  return (
    <section {...props}>
      <Heading variant="title-100" tag={"h2"}>
        {tableData.table_caption}
      </Heading>
      <TableContainer>
        <Table aria-label="table">
          <TableHead>
            <StyledTableRow>
              {tableData.table_column_headers.map((heading, index) => (
                <StyledTableCell
                  sx={{
                    fontSize: "var(--purpur-typography-scale-100)",
                  }} /* MUI requires this way of styling */
                  key={`table-heading-${index}`}
                >
                  {heading}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {tableData.table_data.map((row, index) => (
              <StyledTableRow key={`row-${index}`}>
                {row.map((cell, index) => (
                  <StyledTableCell key={`row-cell-${index}`}>
                    <Cell>{renderIconOrText(cell)}</Cell>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {tableData.table_footer && <p style={{ margin: "0.5rem" }}> {tableData.table_footer}</p>}
    </section>
  );
};

export default TermsTable;
