import React from "react";
import { Paragraph } from "@purpur/purpur";
import { MessageObject } from "../App";
import styled from "styled-components";

const UnorderedList = styled.ul`
  li::marker {
    font-size: 2rem;
  }
  list-style-type: disc;
`;

export function BulletList({ bulletList }: { bulletList: MessageObject | undefined }) {
  if (!bulletList) {
    return null;
  }

  return (
    <UnorderedList>
      {Object.entries(bulletList).map((entry, index) => (
        <li key={`bullet-${index}-${entry[0]}`}>
          <Paragraph>{entry[1]()}</Paragraph>
        </li>
      ))}
    </UnorderedList>
  );
}
