import React from "react";
import TermsSection from "../TermsSection";
import styled from "styled-components";
import { MessageObject } from "../App";
import { useMessage } from "@messageformat/react";

const OrderedList = styled.ol`
  li::marker {
    font-weight: var(--purpur-typography-weight-bold);
    font-size: clamp(var(--purpur-typography-scale-200), 3vw, var(--purpur-typography-scale-400));
  }
`;

type ContentObject = { [key: string]: Content };

type Content = {
  title: () => string;
  paragraphs: MessageObject;
  bulletList?: MessageObject;
  tablePath: MessageObject;
};

export function OrderedTermList() {
  const content: ContentObject = useMessage("content");
  return (
    <OrderedList>
      {Object.entries(content).map((entry, index) => {
        return (
          <TermsSection
            id={`terms-section-${index}`}
            title={entry[1].title}
            paragraphs={entry[1].paragraphs}
            bulletList={entry[1].bulletList ?? undefined}
            tablePath={entry[1].tablePath ?? undefined}
          />
        );
      })}
    </OrderedList>
  );
}
