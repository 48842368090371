import React from "react";
import { Heading } from "@purpur/purpur";
import { MessageObject } from "../App";
import styled from "styled-components";
import { BulletList } from "../BulletList";
import { Content } from "../Content";
import TermsTable from "../Table";

const Wrapper = styled.li`
  margin-top: 6rem;
`;

type TermsSectionProps = {
  title: () => string;
  paragraphs: MessageObject;
  bulletList?: MessageObject;
  tablePath?: MessageObject;
  id: string;
};
const TermsSection = (props: TermsSectionProps) => {
  const { title, paragraphs, bulletList, tablePath, id } = props;

  return (
    <Wrapper key={id}>
      <Heading tag="h2" variant="title-200">
        {title()}
      </Heading>
      <Content paragraphs={paragraphs} />
      <BulletList bulletList={bulletList} />
      {/* MessageObject is of type {0: () => string, therefore Object.values is needed} */}
      {tablePath
        ? Object.values(tablePath).map((path, index) => (
            <TermsTable
              key={"table-" + index}
              style={{ marginTop: "5rem" }}
              tableDataPath={path()}
            />
          ))
        : null}
    </Wrapper>
  );
};

export default TermsSection;
