import React from "react";

const LENGTH_OF_A_TAG = 3;

export function containsLink(stringWithMaybeLink: string): boolean {
  return stringWithMaybeLink.indexOf("<a>") != -1;
}

export function parseLink(stringWithMaybeLink: string): React.ReactNode {
  let paragraphArr = [stringWithMaybeLink];

  if (!containsLink(stringWithMaybeLink)) {
    return paragraphArr;
  }

  const stringWithLink = stringWithMaybeLink;

  const startIndex = stringWithLink.indexOf("<a>") + LENGTH_OF_A_TAG;
  const endIndex = stringWithLink.indexOf("</a>");

  const linkString = stringWithLink.slice(startIndex, endIndex);
  const link = createLink(linkString);

  const paragraphWithoutLink = stringWithLink.split("<a>" + linkString + "</a>");
  return (
    <>
      {paragraphWithoutLink[0]}
      {link}
      {paragraphWithoutLink[1]}
    </>
  );
}
function createLink(link: string): React.ReactNode {
  return (
    <a href={link} target="_blank">
      {link}
    </a>
  );
}
