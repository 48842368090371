import { useEffect, useState } from "react";
import { MessageObject } from "@messageformat/react";
import { currentLanguage } from "@telia/b2b-i18n";
import { logError } from "@telia/b2x-logging";

export function useMessageLocale() {
  const [messages, setMessages] = useState<MessageObject>();
  const locale = currentLanguage();

  useEffect(() => {
    import(`../../locales/locale.${locale}.yaml`)
      .then((m) => {
        setMessages(m.default);
      })
      .catch((reason) => {
        logError("b2b-terms", `Unable to import messages for locale ${locale}, ${reason}`);
        console.log(reason);
      });
  }, [locale]);

  return messages;
}
